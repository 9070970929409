<script lang="ts" setup>
import { useArticleStore } from "@/stores/article";

const articleStore = useArticleStore();
await articleStore.fetchAll({ per_page: 4 });
</script>

<template lang="pug">
v-container(fluid)
  v-row
    v-col(
      cols="6"
      md="3"
      sm="3"
      xs="3"
      v-for="item in articleStore.items"
      :key="item.id"
    )
      v-card(
        class="mx-auto"
        outlined
        link
        :href="item.detailPath"
      )
        v-img(
          :src="item.top_image.thumb.url"
          height="auto"
        )
        v-card-subtitle(class="pt-1")
          v-row(no-gutters align="center" justify="end" class="ma-0")
            v-col
              span(class="published-at") {{item.published_at}}
            v-col
              v-chip(
                class="ma-1"
                size="x-small"
              ) {{item.category.name}}
            v-col
        v-card-title(
          class="pa-1 ma-1"
        )
          h3 {{item.title }}

  v-row(text-center align="center" justify="center")
    v-col(
      class="view-btn col-10 col-sm-4"
    )
      a(href="/articles")
        p VIEW ALL MAGAZINE
        span マガジンをすべて見る
</template>

<style lang="scss" scoped>
.v-card,
img {
  max-width: 300px;
  min-height: 300px;
}

.v-card-title {
  font-size: 14px;
  letter-spacing: -0.56px;
  line-height: 21px;
  margin: 15px 0;
  text-align: left;
  color: #2a2929;
  white-space: inherit;
}

.v-card-subtitle {
  font-size: 10px;
  letter-spacing: -0.1px;
  line-height: 15px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.published-at,
.author {
  font-family: a-otf-gothic-bbb-pr6n, sans-serif;
  font-size: 10px;
  letter-spacing: 0.6px;
  display: block;
  color: #636064;
}

.about-article {
  margin: 5px;
}

.view-btn {
  a {
    text-decoration: none;
  }
  color: #fff;
  width: 300px;
  max-width: 300px;
  height: 80px;
  text-align: center;
  margin: 100px;
  border-radius: 14px;
  background-color: #6b6a6a;

  p {
    font-family: brandon-grotesque, sans-serif;
    font-weight: 600;
    font-size: 21px;
    letter-spacing: 1.2px;
    color: #f0f0f0;
    padding-top: 5px;
    margin: 0 auto;
  }

  span {
    font-family: a-otf-gothic-bbb-pr6n, sans-serif;
    font-size: 15px;
    border: none;
    background: transparent;
    color: #f0f0f0;
    padding: 0;
    margin: 0 auto;
  }
}

.divider {
  border: 0.5px dashed;
  margin: 0 auto 100px;
}

@media (max-width: 576px) {
  .v-container {
    padding: 10px;

    .v-card,
    img {
      max-width: none;
    }
    .v-card-title {
      font-size: 13px;
    }
    .view-btn {
      margin: 20px;
    }
    .divider {
      margin: 0 auto 10px;
    }

    .v-tip {
      font-size: 10px;
    }

    .v-card,
    img {
      max-width: 100%;
    }
  }
}
</style>
