<script lang="ts" setup>
const props = defineProps({
  items: Array,
});

const btnLink = computed(() => {
  let txts;
  switch (props.items[0].itemType) {
    case "dog-run":
      txts = ["VIEW ALL DOGRUN", "ドッグランをすべて見る", "/dog-run/search"];
      break;
    case "dog-cafe":
      txts = [
        "VIEW ALL CAFE",
        "愛犬と入れるカフェをすべて見る",
        "/dog-cafe/search",
      ];
      break;
    case "dog-hotel":
      txts = [
        "VIEW ALL HOTEL",
        "愛犬と泊まれるホテルをすべて見る",
        "/dog-hotel/search",
      ];
      break;
    case "pet-salon":
      txts = [
        "VIEW ALL SALON",
        "愛犬のためのサロンをすべて見る",
        "/pet-salon/search",
      ];
      break;
    case "campsite":
      txts = [
        "VIEW ALL CAMPSITE",
        "愛犬と行けるキャンプ場をすべて見る",
        "/campsite/search",
      ];
      break;
  }
  return txts;
});
</script>

<template lang="pug">
v-container(fluid)
  v-row
    v-col(
      cols="6"
      md="3"
      sm="3"
      xs="3"
      v-for="item in items"
      :key="item.id"
    )
      v-card(
        class="mx-auto"
        outlined
        link
        :href="item.detailPath"
      )
        v-img(
          :src="item.image1.thumb.url"
          itemprop="image"
          height="auto"
        )
        v-card-title(
          class="pl-2 pb-0"
          :class="item.itemType"
        )
          h3 {{item.name }}
        v-card-subtitle(class="pl-2 pt-1") {{ item.fullAddress }}
        div(class="ma-0 text-left")
          v-chip(
            :key="i"
            v-for="(feature, i) in item.features"
            class="ma-1"
            :class="item.itemType"
            size="x-small"
          ) {{feature.name}}
  v-row(text-center align="center" justify="center" v-if="btnLink")
    v-col(
      class="view-btn col-10 col-sm-4"
      :class="items[0].itemType"
    )
      a(:href="btnLink[2]")
        p {{btnLink[0]}}
        span {{btnLink[1]}}
  div.divider(:class="items[0].itemType")
</template>

<style lang="scss" scoped>
.v-card,
img {
  max-width: 300px;
}
.v-card-title {
  font-weight: bold;
  text-align: left;
  font-size: 16px;
  line-height: 21px;
}

.v-card-subtitle {
  font-size: 10px;
  letter-spacing: -0.4px;
  line-height: 15px;
  text-align: left;
}

.v-chip {
  color: white;
  &.dog-run {
    background-color: #2f8859;
  }

  &.dog-cafe {
    background-color: #f4774a;
  }

  &.dog-hotel {
    background-color: #51a3dc;
  }

  &.pet-salon {
    background-color: #f08e9f;
  }

  &.campsite {
    background-color: #f6e75d; // yellow-lighten-3
    color: #212121; // grey-darken-4
  }
}

.view-btn {
  a {
    text-decoration: none;
  }
  color: #fff;
  max-width: 300px;
  height: 80px;
  text-align: center;
  margin: 100px;
  border-radius: 14px;

  &.dog-run {
    background-color: #2f8859;
  }

  &.dog-cafe {
    background-color: #f4774a;
  }

  &.dog-hotel {
    background-color: #51a3dc;
  }

  &.pet-salon {
    background-color: #f08e9f;
  }

  &.pet-salon {
    background-color: #f08e9f;
  }

  &.campsite {
    background-color: #f6e75d; // yellow-lighten-3
    p,
    span {
      color: #212121; // grey-darken-4;
    }
  }

  p {
    font-family: brandon-grotesque, sans-serif;
    font-weight: 600;
    font-size: 21px;
    letter-spacing: 1.2px;
    color: #f0f0f0;
    padding-top: 5px;
    margin: 0 auto;
  }

  span {
    font-family: a-otf-gothic-bbb-pr6n, sans-serif;
    font-size: 15px;
    border: none;
    background: transparent;
    color: #f0f0f0;
    padding: 0;
    margin: 0 auto;
  }
}

.divider {
  border: 0.5px dashed;
  margin: 35px;
  &.dog-run {
    color: #2f8859;
  }

  &.dog-cafe {
    color: #f4774a;
  }

  &.dog-hotel {
    color: #51a3dc;
  }

  &.pet-salon {
    color: #f08e9f;
  }
}

@media (max-width: 576px) {
  .v-container {
    padding: 10px;
    .v-card,
    img {
      width: 100%;
      max-width: none;
    }
    .view-btn {
      margin: 20px;
    }
    .divider {
      margin: 0 auto 10px;
    }

    .v-card-title {
      font-size: 13px;
      line-height: 16px;
    }

    .v-chip.v-size--small {
      border-radius: 12px;
      font-size: 10px;
      height: 17px;
    }
  }
}
</style>
